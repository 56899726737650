window.PUSHER = (function() {


    var handler = function(event, data) {
        // handler standard notification events
        switch(event) {
            case 'open':
                $('#slow-action-message').hide();
                document.location = data.url;
                break;
            case 'error':
                $('#slow-action-message').hide();
                alert(data.message);
                break
            default:
                console.log('Pusher event',event, data)
        }
    }

    var pusher = null;

    var connect = function (key, channelName, cb) {
        if (!pusher) {
            pusher = new Pusher(key, {cluster: 'eu'});
        }
        var channel = pusher.subscribe(channelName);
        cb = cb || handler;
        channel.bind_global(cb);
        return channel;
    }
    return {
         connect: connect
    }

})()