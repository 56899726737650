$(document).on(loadEvent, function () {

    const edtBtn = $('#edit-regions');
    edtBtn.on('click', function () {
        const bdy = $('#regions-body')
        bdy.toggleClass('write');
        if (bdy.hasClass('write')) {
            edtBtn.html('Stop')
            $('[data-link-to]').each((i,el) => {
                const tr = $(el);
                tr.attr('data-x-link-to', tr.attr('data-link-to'));
                tr.removeAttr('data-link-to');
            });
        } else {
            edtBtn.html('Edit')
            $('[data-x-link-to]').each((i,el) => {
                const tr = $(el);
                tr.attr('data-link-to', tr.attr('data-x-link-to'));
                tr.removeAttr('data-x-link-to');
            })
        }
    });

    $('.population-input').on('focus', function () {
        $(this).removeClass('updated').removeClass('error')
    })

    $('.population-input').on('change', function () {
        const that = $(this);
        const val = that.val();
        const regionId = that.attr('data-region-id');
        const url = `/administration/regions/${regionId}`;
        let formData = new FormData();
        formData.append('region[population]', val);
        const csrfToken = document.querySelector("[name='csrf-token']").content
        fetch(url, {method: "PATCH", headers:{"X-CSRF-Token": csrfToken,'X-Requested-With': 'XMLHttpRequest'}, body: formData})
            .then((response) => {
                $(`#population-text-${regionId}`).html(val);
                that.addClass('updated')
            })
            .catch((err) => {
                that.addClass('error')
            });
    });

});