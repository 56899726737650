$(document).on(loadEvent, function () {

    $('li.page.gap a').on('click', function() {
        console.log('weel heelo there');
        // get gap wrapper pages.
        var that = $(this);
        var li = that.parent();
        var prev = li.prev().find('a');
        var next = li.next().find('a');
        var url = next.attr('href');
        var prevPage = parseInt(prev.text());
        var nextPage = parseInt(next.text());
        var middle = Math.round((nextPage - prevPage)/2) + prevPage;
        url = url.replace('page='+nextPage,'page='+middle);
        debugger;
        document.location = url;
        return false;
    })

    $('#event_region_id').on('change', function() {
       var region_id = this.value
        // get region var json and populate admin vars
        var url = '/events/region_vars/' + region_id;
       $.getJSON(url, function(data) {
           for (var id in data) {
               var pc =$('#'+id+'_pc');
               if (pc.length > 0) pc.val(parseFloat(data[id]* 100));
               $('#'+id).val(data[id]).change();
           }
           $('#visitor_activity_c10_pc').trigger('keyup');  // force update of overview window
       })
    })

    var inputFile = function (el) {
        return el.prev();
    }

    var inputLabel = function (el) {
        return el.next();
    }

    var focus = function (el) {
        var button = $(el);
        var fileInput = inputFile(button);
        fileInput[0].focus();
    }

    $('.input-file-trigger').keydown(function (event) {
        if (event.keyCode == 13 || event.keyCode == 32) {
            focus(this)
        }
    }).click(function (event) {
        focus(this);
        return false;
    });

    var startCount = 0;

    var immediateStart = function(el, file, src) {
        startCount += 1;
        $('[type=submit]').attr('disabled',true);
        setTimeout(function() {
            var du = new DUController(el, file);
            du.start(function (error, blob) {
                if (error) {
                    console.log('sorry there was a problem',error);
                } else {
                    // console.log('0k now what')
                    src.val(''); // reset the field so files not resaved on submit
                }
                startCount -= 1;
                if (startCount == 0) {
                    $('[type=submit]').removeAttr('disabled');
                }
            })
        },10);
    }

    var tiggerEl = null;

    $('.input-file, .input-attachment').change(function (event) {
        var that = $(this);
        var el = this
        var noSpreadsheet = true;
        var label;
        if (this.files.length > 0) {
            var names = [];
            for (var i = 0; i < this.files.length; i++) {
                var file = this.files[i];
                // from https://stackoverflow.com/questions/47824245/can-activestorage-be-configured-to-start-uploading-as-soon-as-the-file-is-attach
                // events from https://github.com/rails/rails/blob/master/activestorage/app/javascript/activestorage/direct_upload.jsgit
                // however doesn't trigger feedback as below
                // and how do we know when its finished?

                if (that.attr('data-immediate-upload')) {
                    console.log('triggering keyup');
                    tiggerEl = that;
                    // that.trigger('keyup') // force form to be marked as dirty - not doing this now as event saved before uploading complete no
                    immediateStart(el, file, that);

                }

                var name = file.name;
                names.push(name);
                if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    noSpreadsheet = false;
                }
            }
            label = names.length + (names.length == 1 ? ' file' : ' files') + ': ' + names.join(', ')
        } else {
            label = 'Select a file...'
        }
        if (that.hasClass('.input-file')) {
            var inputlabel = inputLabel(that);
            inputlabel.html(label);
            if (that.attr('data-spreadsheet') && (noSpreadsheet)) {
                $('#submit-btn').attr('disabled', true);
                inputlabel.html('No evaluation model selected');
                inputlabel.addClass('btn-danger')
                return false;
            } else {
                inputlabel.removeClass('btn-danger')
                $('#submit-btn').removeAttr('disabled')
            }
            if (that.attr('data-submit')) {
                $('#submit-btn').click();
                // that[0].form.submit();
            }
        }
    });

    if ($('.input-file, .input-attachment').length > 0) {
        // direct_uploads.js


        addEventListener("direct-upload:initialize", function (event) {
            var target = event.target;
            var input = $(target);
            var detail = event.detail;
            var id = detail.id;
            var file = detail.file;
            var html = input.attr('data-progress');
            if  (!html) {
                html = '<div id="direct-upload-' + id + '" class="direct-upload direct-upload--pending">';
                html += '<div id="direct-upload-progress-' + id + '" class="direct-upload__progress" style="width: 0%"></div>';
                html += ' <span class="direct-upload__filename">' + file.name + '</span></div>';
                var label = $(target).next();
                var cleared = label.attr('data-cleared');
                if (!cleared) {
                    label.html('');
                    label.attr('data-cleared', true);
                }
                var l = label.html();
                label.html(l + html);
            } else {
                var el = $(html)
                el.attr('id', "direct-upload-" + id).addClass('direct-uploading');
                var parts = file.name.split('.')
                var suffix = parts[parts.length - 1];
                el.find('.file-icon').html(file.name).attr('data-type',suffix)
                input.parent().before(el);
                el.trigger("direct-upload:initialize");
            }
            //target.insertAdjacentHTML("beforebegin", html);
             event.stopImmediatePropagation();  //stop this firing again if listener registered more than once
        })

        addEventListener("direct-upload:start", function (event) {
            var id = event.detail.id;
            var element = document.getElementById('direct-upload-' + id);
            $(element).trigger("direct-upload:start");
            element.classList.remove("direct-upload--pending");
        })

        addEventListener("direct-upload:progress", function (event) {
            var id = event.detail.id;
            var element = document.getElementById('direct-upload-' + id);
            var progress = event.detail.progress;
            $(element).trigger("dup",[progress]);
            console.log('event.js jquery is',jQuery.myTemp);
            console.log('element is',element)
            var progressElement = document.getElementById('direct-upload-progress-' + id)
            if (progressElement) {
                progressElement.style.width = progress + '%';
            }
        })

        addEventListener("direct-upload:error", function (event) {
            event.preventDefault();
            var id = event.detail.id;
            var error = event.detail.error;
            var element = document.getElementById('direct-upload-' + id);
            element.classList.add("direct-upload--error");
            element.setAttribute("title", error);
            $(element).trigger("direct-upload:error",error);
            $(element).removeClass('direct-uploading');
        })

        addEventListener("direct-upload:end", function (event) {
            var id = event.detail.id;
            var target = event.target;
            var label = $(target).next();
            label.removeAttr('data-cleared');
            logged = [];
            var element = document.getElementById('direct-upload-' + id);
            element.classList.add("direct-upload--complete");
            $(element).trigger("direct-upload:end",event);
            $(element).removeClass('direct-uploading');
            tiggerEl.trigger('keyup') //force form to be marked as dirty
        })
    }


    $(document).off('submit.sharing').on('submit.sharing','#event-share-form',function(e) {

        e.preventDefault(); // avoid to execute the actual submit of the form.
        e.stopPropagation();

        var form = $(this);
        var url = form.attr('action');
        var parts = url.split('/');
        var oeid = parts[parts.length - 1];

        $.ajax({
            type: "POST",
            url: url,
            data: form.serialize(), // serializes the form's elements.
            success: function(data)
            {
                $('#ajax-modal').modal('hide');
                $('a[data-ajax-modal-url="/events/share_modal/'+oeid+'"]').parent().html(data)

            }
        });

        return false;


    }).off('click.upgrade').on('click.upgrade','.upgrade-event-model',function() {
        var that = $(this);
        var id = that.attr('data-id');
        var url = "/events/upgrade/" + id;
        that.html('<i class="ti-reload fa-spin-reverse" style="display: inline-block"></i>')

        $.ajax({
            type: "POST",
            url: url,
            data: {},
            success: function(data)
            {
                that.remove()
            }
        });

        return false;

    });

})


