/*
$(document).on(loadEvent, function () {

    var xB4 = null;
    var yB4 = null;
    var topB4 = null;
    var leftB4 = null;
    var widthB4 = null;
    var heightB4 = null;
    var movingSticky = null;
    var action = null;

    var startMove = function (el, type, event) {
        movingSticky = el;
        var offset = movingSticky.position();
        topB4 = parseInt(movingSticky.css('top'));
        leftB4 = parseInt(movingSticky.css('left'));
        widthB4 = movingSticky.width();
        heightB4 = movingSticky.height();
        xB4 = event.pageX;
        yB4 = event.pageY;
        action = type;
        $('body').addClass('no-select')
    }

    var saveNote = function (el) {
        var data;
        var left = el.css('left');
        var note = left ? {
            left: parseInt(el.css('left')),
            top: parseInt(el.css('top')),
            width:  parseInt(el.css('width')),
            height: parseInt(el.css('height')),
            note: el.find('.sticky-content').text(),
            path: document.location.pathname
        } : {
            note: el.find('.sticky-content').text(),
            path: document.location.pathname
        }
        var id = el.attr('data-id')
        var url = '/stickies'
        if (id) {
            url += '/' + id + '.json';
            data = {
                sticky: note,
                _method: 'patch'
            }
        } else {
            url += '.json'
            data = {
                sticky: note,
            }
        }
        $.post(url, data, function (data) {
            el.attr('data-id', data.id)
        })
    }

    var trashNote = function (el) {
        var id = el.attr('data-id')
        if (id) {
            var url = '/stickies/' + id + ".json";
            var data = {_method: 'delete'}
            $.post(url, data, function (data) {
            });
        }
        el.remove();
    }

    var timeout = null

    var saveSticky = function(el) {
        var data = {
            sticky: {
                note: el.html(),
                path: document.location.pathname,
            },
            _method: 'patch'
        };
        var id = el.attr('data-id');
        console.log(el.html())
        var url = '/stickies/' + id +'.json';
        $.post(url, data, function (data) {
            console.log(data)
            // el.attr('data-id', data.id)
        })
    }

    $(document).on('input','.fixed-sticky', function() {
        var that = $(this);
        clearTimeout(timeout)
        timeout = setTimeout(function() {
            saveSticky(that)
        },1000)
    })

    $(document).on('click', '#add-sticky', function (ev) {
        var sticky = "<div class='sticky' style='top: 0; left: 0;'><div class='relative'><div class='sticky-move'></div><i class='ti-close sticky-close'></i></div>"
        sticky += "<div class='sticky-content' contenteditable='true'></div>"
        sticky += "<div class='relative'><div class='sticky-resize'></div></div></div>"
        $('#stickies').append(sticky);
        ev.stopImmediatePropagation();
        ev.stopPropagation();
        ev.preventDefault();
        return false;
    }).on('focus', '[contenteditable=true]', function () {
        var that = $(this)
        that.parent().addClass('editing');
    }).on('blur', '[contenteditable=true]', function () {
        var that = $(this)
        that.parent().removeClass('editing');
        // saveNote(that.parent());
    }).on('mousedown', '.sticky-close', function (event) {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }).on('mouseup', '.ti-close', function (event) {
        if (confirm('Are you sure you want to delete this sticky note?')) {
            trashNote($(this).parent().parent());
        }
        event.preventDefault();
        event.stopPropagation();
        return false;
    }).on('mousedown', '.sticky-move', function (event) {
        startMove($(this).parent().parent(), 'dragging', event);
    }).on('mousedown', '.sticky-resize', function (event) {
        startMove($(this).parent().parent(), 'sizing', event);
    }).on('mouseup', function (event) {
        if (movingSticky) {
            $('body').removeClass('no-select');
            saveNote(movingSticky);
            movingSticky = null;
        }
    }).on('mousemove', function (event) {
        var deltaX = event.pageX - xB4;
        var deltaY = event.pageY - yB4;
        if ((movingSticky) && (action == 'dragging')) {
            var top = topB4 + deltaY;
            var left = leftB4 + deltaX;
            movingSticky.css({top: Math.round(top) + 'px', left: Math.round(left) + 'px'});
        } else if ((movingSticky) && (action == 'sizing')) {
            var height = heightB4 + deltaY;
            var width = widthB4 + deltaX;
            if ((height > 16) && (width > 16)) {
                movingSticky.css({width: Math.round(width) + 'px', height: Math.round(height) + 'px'})
            }
        }
    })

});
*/

$(document).on(loadEvent, function () {

    var timeout = null

    var saveSticky = function(el) {
        var data = {
            sticky: {
                note: el.html(),
                path: document.location.pathname,
            },
            _method: 'patch'
        };
        var id = el.attr('data-id');
        var url = '/stickies/' + id +'.json';
        $.post(url, data, function (data) {
            // el.attr('data-id', data.id)
        })
    }

    $(document).on('input','.fixed-sticky', function() {
        var that = $(this);
        clearTimeout(timeout)
        timeout = setTimeout(function() {
            saveSticky(that)
        },1000)
    })


});