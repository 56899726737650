$(document).on(loadEvent, function () {
    var cookieRef = 'ee-selected-event-ids';

    var saveSelection = function() {
        COOKIE.set(cookieRef, selectedIDs.join(','));
    }

    var selectEvent = function(id) {
        if (id) {
            $('.event-selector-check[data-id="' + id + '"]').addClass('checked');
            selectedIDs.indexOf(id) === -1 ? selectedIDs.push(id) : null;
            saveSelection();
        }
    }

    var deselectEvent = function(id) {
        if (id) {
            $('.event-selector-check[data-id="' + id + '"]').removeClass('checked');
            var idx = selectedIDs.indexOf(id);
            if (idx > -1) {
                selectedIDs.splice(idx, 1);
            }
            saveSelection();
        }
    }

    var selectedIDs = []
    var savedSelection = COOKIE.get(cookieRef).split(',');

    console.log('selecting', savedSelection)
    for (var i = 0; i < savedSelection.length; i++) {
        console.log('processing',i, savedSelection[i])
        selectEvent(savedSelection[i]);
    }

    $('#events-selects-all .select-all').click(function (e) {
        var that = $(this);
        var id = that.attr('id');
        if (id == 'select-none') {
            $('.event-selector-check').removeClass('checked');
            selectedIDs = [];
            saveSelection();
        } else {
            var selected = $('.event-selector-check');
            selected.addClass('checked');
            if (id == 'select-page') {
                var ids = selected.map(function(){ return $(this).attr('data-id') }).get();
              } else {
               var ids = that.attr('data-ids').split(',');

            }
            selectedIDs = ids;
            saveSelection();
        }
        return false
    })

    $('#events .event-selector-check').on('click', function () {
        var that = $(this);
        var id = that.attr('data-id');
        that.hasClass('checked') ? deselectEvent(id) : selectEvent(id);
        return false;
    });

    $('#events-admin .event-benchmark-toggle').on('click', function () {
        var that = $(this);
        var id = that.attr('data-id');
        $.getJSON("/administration/events/benchmark_toggle/"+id,function(result){
            if (result) {
                that.addClass('checked')
            } else {
                that.removeClass('checked')
            }
        })
       that.toggleClass('checked');
        return false;
    });

    $('#events-admin .event-research-toggle').on('click', function () {
        var that = $(this);
        var id = that.attr('data-id');
        $.getJSON("/administration/events/research_toggle/"+id,function(result){
            if (result) {
                that.addClass('checked')
            } else {
                that.removeClass('checked')
            }
        })
        that.toggleClass('checked');
        return false;
    });



    $('.selected-event-action').on('click',function() {
       // $('#action-menu').dropdown('toggle');
        var result = false
        if (selectedIDs.length < 1) {
            alert('Please select an event first');
        } else {
            var that = $(this);
            var href = that.attr('href');
            var loc = href + window.location.search
            loc = setQueryParam(loc,'ids',selectedIDs.join(','))
           that.attr('href', loc)
            var text = that.text();
            if (text == 'Request validation') text += ' for';
            result =  confirm('Are you sure you want to '+text+' the '+selectedIDs.length+' selected event'+ (selectedIDs.length == 1 ? '' : 's') + '?')
        }
        if (!result) {
            $('#action-menu').dropdown('toggle');
        }
        return result
    });

    $('#deleted-events').on('click',function() {
        selectedIDs = [];
        saveSelection();
    })
});