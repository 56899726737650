window.dispatchEvent = function(element, type, eventInit) {
    eventInit = eventInit || {}
    var disabled = element.disabled;
    var bubbles = eventInit.bubbles;
    var cancelable = eventInit.cancelable;
    var detail = eventInit.detail;
    var event = document.createEvent("Event")

    event.initEvent(type, bubbles || true, cancelable || true)
    event.detail = detail || {}

    try {
        element.disabled = false
        element.dispatchEvent(event)
    } finally {
        element.disabled = disabled
    }

    return event
}

import { DirectUpload } from "@rails/activestorage";

window.DUController = function(input, file) {
    var duc = this;
    this.input = input;
    this.file = file;
    this.hiddenInput = null;

    this.url = function () {
        return duc.input.getAttribute("data-direct-upload-url")
    }

    this.dispatchError = function (error) {
        var event = duc.dispatch("error", {error: error})
        if (!event.defaultPrevented) {
            alert(error)
        }
    }

    this.dispatch = function (name, detail) {
        detail = detail || {}
        detail.file = duc.file
        detail.id = duc.directUpload.id;
        if (duc.hiddenInput) {
            detail.signed_id = duc.hiddenInput.value;
        }
        return window.dispatchEvent(duc.input, 'direct-upload:' + name, {detail: detail})
    }

    this.uploadRequestDidProgress = function (event) {
        var progress = event.loaded / event.total * 100
        if (progress) {
            duc.dispatch("progress", {progress: progress})
        }
    }

   this.directUploadWillCreateBlobWithXHR = function (xhr) {
        duc.dispatch("before-blob-request", {xhr: xhr})
    }


    this.directUploadWillStoreFileWithXHR = function (xhr) {
        duc.dispatch("before-storage-request", {xhr: xhr})
        xhr.upload.addEventListener("progress", function (event) {
            duc.uploadRequestDidProgress(event)
        })
    }

    this.start = function (callback) {
        duc.hiddenInput = document.createElement("input")
        duc.hiddenInput.type = "hidden"
        duc.hiddenInput.name = duc.input.name
        duc.input.insertAdjacentElement("beforebegin", duc.hiddenInput)

        duc.dispatch("start")

        duc.directUpload.create(function (error, attributes) {
            if (error) {
                duc.hiddenInput.parentNode.removeChild(duc.hiddenInput)
                duc.dispatchError(error)
            } else {
                duc.file.id = attributes.id
                duc.hiddenInput.value = attributes.signed_id
            }
            duc.dispatch("end")
            callback(error)
        })
    }

    this.directUpload = new DirectUpload(duc.file, duc.url(), duc)
    this.dispatch("initialize")
}

