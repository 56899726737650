notOn = function (selector) {
    return $(selector).length < 1
}

var setQueryParam = function(locString, param, value) {
    // sets the query parameter on the location string and returns in full (removes if null)
    var parts = locString.split('?');
    var location = parts[0];
    var bits = parts[1] ? parts[1].split('&') : [];
    var hash  = {}
    for (var i = 0; i < bits.length; i++) {
        var items = bits[i].split('=')
        hash[items[0]] = items[1]
    }
    if (value != null) {
        hash[param] = value;
    } else {
        delete hash[param];
    }
    if (Object.keys(hash).length > 0) {
        var arr = [];
        for (var i in hash) {
            arr.push(i+'='+hash[i])
        }
        return location + '?' + arr.join('&')
    } else {
        return location
    }
}

$(document).on('click', '.restricted', function (e) {
    $('#login').modal('show')
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    return false
}).on('click', 'th[data-order]', function () {
    var loc = window.location.href
    //var path = loc.pathname;
    //var query = loc.search.replace(/o=[\w\.]*/, '');
    //query = query.replace(/d=\w+/, '');
    //var order = 'o=' + $(this).attr('data-order');
    // query = ((query.length > 2) ? query + '&' + order : '?' + order);
    loc = setQueryParam(loc,'o',$(this).attr('data-order'));
    var icon = $(this).find('i');
    if (icon.hasClass('ti-arrow-down')) {
        loc = setQueryParam(loc,'d','true');
    } else {
        loc = setQueryParam(loc,'d',null);
    }
    document.location = loc;
}).on('click', 'tr[data-link-to]', function (evt) {
    if (evt.target.nodeName === "INPUT") return;
    var closest = $(evt.target).closest('tr,a')
    if (closest.attr('data-link-to')) {
        document.location = $(this).attr('data-link-to');
    }
}).on('click', '[data-ajax-modal-url]', function () {
    var that = $(this);
    var url = that.attr('data-ajax-modal-url');
    $('#ajax-modal .modal-content').load(url, function () {
        $('#ajax-modal').modal({show: true});
    });
    return false;
}).on('click', "[data-toggle]", function () {
    var that = $(this);
    var selector = that.attr('data-toggle');
    $(selector).toggleClass(that.attr('data-toggle-class'));
    return false;
}).on('click', '#login.modal .forgot-password', function () {
    var url = $(this).attr('href');
    $('#login .modal-content').load(url);
    return false;
}).on('click', '#login.modal .sign-in', function () {
    var url = $(this).attr('href');
    $('#login .modal-content').load(url);
    return false;
}).on('submit','#organisation-name-form',function(e) {

    e.preventDefault(); // avoid to execute the actual submit of the form.

    var form = $(this);
    var url = form.attr('action');


    $.ajax({
        type: "PUT",
        url: url,
        data: form.serialize(), // serializes the form's elements.
        success: function(data)
        {
            $('#organisation-name').modal('hide').on('hidden.bs.modal', function (e) {
                $('#organisation-name').remove();
            });
        }
    });


}).on('click','[data-slow-action="true"]',function() {
    var that = $(this);
    var url = that.attr('href');
    var conjunctive =  (url.split('?').length > 0) ? '&' : '?';
    url += conjunctive + "slow_action=true";
    if (that.attr('data-pdf') == 'true') {
        url += '&pdf=true'
    }
    $.get(url);
    that.closest('.dropdown').find(".dropdown-toggle").trigger('click');  // close the drop down can't seem use dropdown toggle for some reason (menu doesn't respond to next click)
    $('#slow-action-message').show();
    return false;
});


$(document).ready(function () {

}).on(loadEvent, function () {
    $('.datepicker').datepicker({autoHide: true, format: 'dd-mm-yyyy'});
    $('.datepicker').change(function() {
        var that = $(this);
        if (that.val() != '') {
            that.val(that.datepicker('getDate',true));
        }
    });

    //$('.selectpicker').select2();
    // $('.selectpicker').selectize()

    $('[data-role=tags]').each(function(){
        var that = $(this);
        var hints = that.attr('data-tag-hints').split(',');
        that.tagify({
            whitelist: hints,
            dropdown: {
                enabled: 0
            }
        })
    });
    console.log('setting chosen')
    $('.chosen-select').chosen({
        allow_single_deselect: true,
        no_results_text: 'No results matched',
        width: '100%'
    });
    $('#organisation-name').modal({show: true, backdrop: 'static', keyboard: false});
});
