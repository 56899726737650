(function () {

    Array.prototype.unique = function () {
        return this.filter(function (value, index, self) {
            return self.indexOf(value) === index;
        });
    }

    Array.prototype.difference = function (a) {
        return this.filter(function (i) {
            return a.indexOf(i) < 0;
        });
    };

    var selectionCookieName = function (model) {
        return 'BE' + model + 'Selected';
    }

    var maxCookieLen = function () {
        return 3000;
    }

    var cookieConjunctive = function () {
        return '&';
    }

    var getCookie = function (cookie) {
        var idx = 0;
        var part = COOKIE.get(cookie);
        while (part.endsWith(cookieConjunctive())) {
            idx = idx + 1
            part = part.slice(0, -cookieConjunctive().length) + COOKIE.get(cookie + idx);
        }
        return part;
    }

    var setCookie = function (cookie, value) {
        var idx = '';
        while (value.length > maxCookieLen()) {
            var part = value.substring(0, maxCookieLen());
            value = value.substring(maxCookieLen());
            COOKIE.set(cookie + idx, part + cookieConjunctive());
            idx = parseInt(idx || '0') + 1
        }
        COOKIE.set(cookie + idx, value);
    }

    var updateSelection = function ($el, combinator) {
        var model = $el.attr('data-model');
        var id = $el.map(function () {
            return $(this).attr('data-id').split('|')
        }).toArray().flat();
        // var id = $el.attr('data-id').split('|');
        var ids = Array.isArray(id) ? id : [id];
        var cookieName = selectionCookieName(model);
        var cookie = getCookie(cookieName)
        debugger;
        var existing = cookie ? cookie.split('|') : [];
        var all = combinator(existing, ids);
        setCookie(cookieName, all.join('|'));
        return all;
    }

    var selectOnly = function ($el) {
        return updateSelection($el, function (existing, ids) {
            return ids;
        });
    }

    var selectRecord = function ($el) {
        return updateSelection($el, function (existing, ids) {
            return ids.concat(existing).unique();
        });
    }

    var deselectRecord = function ($el) {
        return updateSelection($el, function (existing, ids) {
            return existing.difference(ids);
        });
    }

    var clearSelection = function ($el) {
        return updateSelection($el, function (existing, ids) {
            return [];
        });
    }

    var getSelection = function ($el) {
        return updateSelection($el, function (existing, ids) {
            return existing;
        });
    }

    var highlight = function (el) {
        var that = $(el);
        var tr = that.closest('tr, .tr');
        if (el.checked) {
            tr.addClass('selected')
        } else {
            tr.removeClass('selected')
        }
    }

    var redraw = function () {
        var groups = $('.select-group');
        groups.each(function () {
            var group = $(this);
            var els = group.find('.multi-record-select');
            if (els.length > 0) {
                var all = getSelection(els);
                var len = all.length
                var isAll = all[0] && all[0].startsWith('all-')
                if (isAll) {
                    var selAll = group.find('.select-all-all')
                    var shouldBe = selAll.attr('data-id');
                    if (all[0] != shouldBe) {
                        // we need to correct for change in search or other selection
                        selectRecord(selAll)
                        all[0] = shouldBe
                    }
                    len =  parseInt(all[0].split('-')[1])
                }
                group.find('.select-count').html(len);
                group.find('.select-plural-s').html(len == 1 ? '' : 's');
                els.each(function () {
                        var that = $(this);
                        var id = '' + that.attr('data-id');
                        if (isAll || (all.indexOf(id) >= 0)) {
                            this.checked = true;
                        } else {
                            this.checked = false;
                        }
                        highlight(this);
                    }
                )
            }
        });
    }

    var lastSelected

    $(document).on('click', '.multi-record-select+label', function (event) {
        // have to use label rather than checkbox itself to get shift click and command click to work on firefox
        var that = $('#' + this.getAttribute('for'));
        var el = that[0]
        el.checked = !el.checked //becuase we redraw from current checked items we're going to set it manually and stop the event propagating
        var shift = event.shiftKey;
        var ctrl = (event.ctrlKey || event.metaKey) || !el.checked;
        if (shift) {
            if (!lastSelected) {
                lastSelected = that.closest('.select-group').find('.multi-record-select:checked')[0]
            }
            if (!lastSelected) {
                ctrl = false; //turn into standard click if no previous click
            } else {
                // check last item clicked still on screen
                var last = that.closest('.select-group').find('.multi-record-select[data-id="' + lastSelected.getAttribute('data-id') + '"]');
                if (last.length < 1) {
                    ctrl = false;
                } else {
                    ctrl = true // want to add or remove from existing selection
                    //create a selection in that of all checkboxes between last as current
                    var all = that.closest('.select-group').find('.multi-record-select');
                    var idx1 = all.index(that);
                    var idx2 = all.index(last);
                    var from = idx1 < idx2 ? idx1 : idx2;
                    var upto = (idx1 < idx2 ? idx2 : idx1) + 1;
                    that = all.slice(from, upto);
                }
            }
        }
        if (ctrl) {
            el.checked ? selectRecord(that) : deselectRecord(that);
            if (that.length > 1) {
                redraw();
            } else {
                highlight(el);
            }
        } else {
            el.checked ? selectOnly(that) : clearSelection(that);
            redraw()
        }
        if (el.checked) {
            lastSelected = el
        }
        return false;
    });

    $(document).on('click', '.select-all', function () {
        var that = $(this);
        var ids = that.attr('data-id');
        if (ids) {
            selectRecord(that);
        } else {
            clearSelection(that);
        }
        redraw();
        return false;
    }).on('click', '.select-show', function () {
        $('[data-search-model]').val('selected').trigger('keyup');
    })

    $(document).on(GLOBALS.readyEvent, function () {
        redraw();
    }).on("ajaxSuccess", function (e) {
        redraw();
    });

})();